
a {
  text-decoration: none;
}



.btn-visit-comparator {
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.btn-visit-comparator:hover {
  background-color: #333;
  color: white !important;
}

.newNavbar {
  border-bottom: 1px solid #e2e2e2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05) !important;
}
.newNavbar a {
  text-decoration: none;
}

.newNavbar a:not(.navbarBtnLink):not(.navbarLogo):hover {
  color: #0076ff8f;
}

.newNavbar .navbarLogo:hover {
  color: black !important;
}

.NavBarLogoSmall {
  width: 130px;
  height: 90px;
  padding: 15px;
  padding-bottom: 0 !important;

}

@media (max-width: 767px) {
  .NavBarLogoSmall {
    position: relative;
    width: 100px;
    height: 70px;
  }
}


.navbarDropDown hr {
  width: 100%;
  margin: 15px;
}

.navbarDropDown small {
  width: 100% !important;
}

.navbarDropDown button {
  width: 85%;
  font-size: 14px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 7px 1rem !important;
  font-weight: 600;
}

.navbarDropDown button:first-of-type {
  background-color: #e5e6ea !important;
  border-radius: 5px;
  color: #000000;
  margin-bottom: 7px;
}

.navbarDropDown button:nth-of-type(2) {
  background-color: #f64f4f !important;
  border-radius: 5px;
  color: white;
}

.navbarDropDown button:hover{
  opacity: 0.9;
}


@media (min-width: 767px) {

  .newNavbar div:not(.navbarDropDown):first-child {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    /*flex-direction: row !important;*/
  }

  .newNavbBarLinks {
    flex-direction: row;
    justify-content: flex-end;
    width: fit-content !important;
  }
}


@media (max-width: 767px) {
  .btn-visit-comparator {
    padding: 10px 15px;
    font-size: 12px;
    max-width: 14rem;
    max-height: 2rem;
  }


  .newNavbar {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 1rem !important;
  }

  .newNavbar a:not(.navbarBtnLink) {
    font-weight: bold;
    margin-bottom: 1rem !important;
  }

  .newNavbBarLinks {
    justify-content: space-between;
    width: 100% !important;
  }

  .navbarBtnLinks {
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  .navbarLogo,
  .navbarToggleBtn{
    margin-top: 0 !important;
  }

  .navbarBtnLink:not(.navbarLogo) {
    width: 100% !important;
    font-size: 15px;
    max-height: 35px !important;
  }

}


@media (min-width: 1200px) {

  .newNavbBarLinks {
    width: 100% !important;
    align-items: baseline;
  }
  .newNavbar div:not(.navbarDropDown):first-child{
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    /*flex-direction: row !important;*/
    width: 100%;
    position: relative;
  }
}

.navbarDropDown {
  position: absolute;
  bottom: -215px;
  right: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

